import React from "react"
import { IndexPageTemplate } from "~templates/pages/Index"

const activeDocMeta = {
    lang: "de-de",
    alternateLanguages: [
        { type: "home", lang: "en-gb", url: "/en" },
        { type: "home", lang: "zh-cn", url: "/zh" },
    ],
}

const IndexPage = () => <IndexPageTemplate activeDocMeta={activeDocMeta} />

export default IndexPage
